import React, { useState } from "react";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { notify } from "../../helper/toast";
import useRedirect from "../../hooks/Redirect";

interface SignUpProps {
  onSignup: (role: string, token: string) => void;
}

const Signup: React.FC<SignUpProps> = ({ onSignup }) => {
  const AxiosInstance = useAxios();
  const { redirectTo } = useRedirect();
  const [email, setEmail] = useState("");
  const [clinicName, setClinicName] = useState("");
  const [address, setAddress] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [image, setImage] = useState("");
  const [loading, setLoading] = React.useState(false);
  const handleImageChange = (e: any) => {
    const file = e.target.files[0];
    setImage(file);
  };

  const handleReg = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    let formData = new FormData();
    formData.append("email", email);
    formData.append("name", clinicName);
    formData.append("address", address);
    formData.append("phoneNumber", phone);
    formData.append("password", password);
    formData.append("logo", image);
    AxiosInstance.post(ApiEndPoints.clinicReg, formData, {
      headers: {
        "Content-type": "multipart/form-data;",
      },
    })
      .then((response: any) => {
        localStorage.setItem("token", response?.token);
        localStorage.setItem("clinicId", response?.data?.id);
        onSignup(response?.data?.role, response?.token);
        notify(response?.msg, "success");
        setLoading(false);
        redirectTo("/clinicDashboard");
      })
      .catch((error) => {
        notify(error?.msg, "error");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  return (
    <div className="bg-white w-screen font-sans text-gray-900">
      <div className="">
        <div className="mx-auto w-full sm:max-w-screen-sm md:max-w-screen-md lg:max-w-screen-lg xl:max-w-screen-xl">
          <div className="mx-2 py-12 text-center md:mx-auto md:w-2/3 md:py-20">
            <h1 className="mb-4 text-3xl font-black leading-4 sm:text-5xl xl:text-6xl">
              Sign up
            </h1>
            <div className="text-lg sm:text-xl">
              <div className="">
                <p className="mb-4">
                  Let's do this! Start your 7 Days free trial by filling in our
                  simple form below.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="md:w-2/3 mx-auto w-full pb-16 sm:max-w-screen-sm md:max-w-screen-md lg:w-1/3 lg:max-w-screen-lg xl:max-w-screen-xl">
        <form
          className="shadow-lg mb-4 rounded-lg border border-gray-100 py-10 px-8"
          onSubmit={handleReg}
        >
          <div className="mb-4">
            <label className="mb-2 block text-sm font-bold" htmlFor="email">
              E-mail
            </label>
            <input
              onChange={(e) => setEmail(e.target.value)}
              className="shadow-sm w-full cursor-text appearance-none rounded border border-gray-300 py-2 px-3 leading-tight outline-none ring-blue-500 focus:ring"
              id="email"
              type="email"
              placeholder="email"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block text-sm font-bold" htmlFor="email">
              Clinic Name
            </label>
            <input
              onChange={(e) => setClinicName(e.target.value)}
              className="shadow-sm w-full cursor-text appearance-none rounded border border-gray-300 py-2 px-3 leading-tight outline-none ring-blue-500 focus:ring"
              id="clinicName"
              type="text"
              placeholder="Clinic Name"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block text-sm font-bold" htmlFor="email">
              Address
            </label>
            <input
              onChange={(e) => setAddress(e.target.value)}
              className="shadow-sm w-full cursor-text appearance-none rounded border border-gray-300 py-2 px-3 leading-tight outline-none ring-blue-500 focus:ring"
              id="address"
              type="text"
              placeholder="Clinic Address"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block text-sm font-bold" htmlFor="phone">
              Phone
            </label>
            <input
              onChange={(e) => setPhone(e.target.value)}
              className="shadow-sm w-full cursor-text appearance-none rounded border border-gray-300 py-2 px-3 leading-tight outline-none ring-blue-500 focus:ring"
              id="phone"
              type="phone"
              placeholder="Phone"
              required
            />
          </div>
          <div className="mb-4">
            <label className="mb-2 block text-sm font-bold" htmlFor="password">
              Password
            </label>
            <input
              onChange={(e) => setPassword(e.target.value)}
              className="shadow-sm w-full cursor-text appearance-none rounded border border-gray-300 py-2 px-3 leading-tight outline-none ring-blue-500 focus:ring"
              id="password"
              type="password"
              placeholder="******************"
              required
            />
          </div>
          <div className="mb-4">
            <label
              className="mb-2 block text-sm font-bold"
              htmlFor="clinicImage"
            >
              Clinic Image
            </label>
            <input
              onChange={handleImageChange}
              className="appearance-none rounded border border-gray-300 py-2 px-3 leading-tight outline-none ring-blue-500 focus:ring"
              id="clinicImage"
              type="file"
              accept="image/*"
            />
          </div>
          <div className="mb-6">
            <label className="mb-2 flex text-sm">
              <input type="checkbox" name="accept" className="mr-2" required />
              <div className="text-gray-800">
                <p className="">
                  I accept the
                  <a
                    href="#"
                    className="cursor-pointer text-blue-500 underline"
                  >
                    terms of use
                  </a>
                  and
                  <a
                    href="#"
                    className="cursor-pointer text-blue-500 underline"
                  >
                    privacy policy
                  </a>
                </p>
              </div>
            </label>
          </div>
          <div className="flex items-center">
            <div className="flex-1"></div>
            {!loading ? (
              <button
                type="submit"
                className="flex w-full justify-center rounded-md bg-indigo-600 px-3 py-1.5 text-sm font-semibold leading-6 text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
              >
                Create account
              </button>
            ) : (
              <button
                type="submit"
                className="py-2 px-4 flex justify-center items-center  bg-indigo-600 hover:bg-indigo-700 focus:ring-indigo-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-lg max-w-md"
              >
                <svg
                  width="20"
                  height="20"
                  fill="currentColor"
                  className="mr-2 animate-spin"
                  viewBox="0 0 1792 1792"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M526 1394q0 53-37.5 90.5t-90.5 37.5q-52 0-90-38t-38-90q0-53 37.5-90.5t90.5-37.5 90.5 37.5 37.5 90.5zm498 206q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-704-704q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm1202 498q0 52-38 90t-90 38q-53 0-90.5-37.5t-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-964-996q0 66-47 113t-113 47-113-47-47-113 47-113 113-47 113 47 47 113zm1170 498q0 53-37.5 90.5t-90.5 37.5-90.5-37.5-37.5-90.5 37.5-90.5 90.5-37.5 90.5 37.5 37.5 90.5zm-640-704q0 80-56 136t-136 56-136-56-56-136 56-136 136-56 136 56 56 136zm530 206q0 93-66 158.5t-158 65.5q-93 0-158.5-65.5t-65.5-158.5q0-92 65.5-158t158.5-66q92 0 158 66t66 158z"></path>
                </svg>
                Create account
              </button>
            )}
          </div>
        </form>
      </div>
    </div>
  );
};

export default Signup;
