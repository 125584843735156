import React, { useState } from "react";
import useRedirect from "../../hooks/Redirect";
import Modal from "react-modal";
const LandingPageSection: React.FC = () => {
  const { redirectTo } = useRedirect();
  const [modalIsOpen, setModalIsOpen] = useState(true);

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  return (
    <>
      <section className="bg-[#FCF8F1] bg-opacity-30 py-10 sm:py-16 lg:py-24">
        <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
          <div className="grid items-center grid-cols-1 gap-12 lg:grid-cols-2">
            <div>
              <p className="font-lora text-base font-semibold tracking-wider text-black-600 uppercase">
                Transform your clinic experience with{" "}
                <span className="text-vaidyakram-blue font-lora">
                  Vaidyakram.in
                </span>
                . Reduce wait times and improve patient satisfaction.
              </p>
              <h1 className="font-lora mt-4 text-4xl font-bold text-black lg:mt-8 sm:text-6xl xl:text-4xl">
                Long wait times at clinics are a major source of frustration for
                both patients and doctors in India.{" "}
                <span className="text-vaidyakram-blue font-lora">
                  Vaidyakram.in
                </span>{" "}
                can help!
              </h1>
              <p className="mt-4 text-base text-black lg:mt-8 sm:text-xl"></p>

              <a
                title=""
                className="font-lora inline-flex items-center px-6 py-4 mt-8 font-semibold text-black transition-all duration-200 bg-yellow-300 rounded-full lg:mt-16 hover:bg-yellow-400 focus:bg-yellow-400"
                role="button"
                onClick={() => redirectTo("/search")}
              >
                Search Doctor or Clinic
                <svg
                  className="w-6 h-6 ml-8 -mr-2"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="1.5"
                    d="M13 9l3 3m0 0l-3 3m3-3H8m13 0a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </a>
            </div>

            <div>
              <img
                className="w-full"
                src="https://ik.imagekit.io/kvsrt5kfk/IMG-20240409-WA0001.jpg?updatedAt=1712908958390"
                alt=""
              />
            </div>
          </div>
        </div>
      </section>
      {/* Modal 
      <Modal
  isOpen={modalIsOpen}
  onRequestClose={closeModal}
  contentLabel="Image Modal"
  style={{
    overlay: {
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      zIndex: '100',
    },
    content: {
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '90%', // Set width to 90% of viewport width by default
      maxWidth: '400px', // Set max width to 400px
      height:'80vh',
      padding: '20px',
      border: 'none',
      borderRadius: '8px',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.15)',
    },
  }}
>
  <button
    className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
    onClick={closeModal}
  >
    <svg
      className="w-6 h-6"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M6 18L18 6M6 6l12 12"
      />
    </svg>
  </button>
  <div className="w-full flex justify-center">
    <a href="https://www.cshospitaljaipur.com/" target="_blank">
      <img
        src="https://res.cloudinary.com/ded72ifnt/image/upload/v1713586992/photo1713586954_pm1kwx.jpg"
        alt="Advertisement"
        className="w-full max-w-md h-auto object-cover rounded-lg shadow-md"
      />
    </a>
  </div>
</Modal>
*/}

      {/* Meta tags */}

      <meta name="description" content="Vaidyakram.in empowers patients to generate tokens online or through QR codes, eliminating the need to wait in line." />
      <meta name="keywords" content="queue management, queue application, queue system, token management system, token based system, hospital management system, hospital token system " />
      <meta name="author" content="VaidyaKram" />
    </>
  );
};

export default LandingPageSection;
