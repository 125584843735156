import React from "react";

interface ClinicCardSearchProps {
  isClinicSearch: boolean;
  isClickable: boolean;
  clinicName: string;
  clinicCity: string;
  doctorName: string;
  onBookAppointment: any;
  index: number;
  logo?: string;
  onViewDetails?: any;
}

const ClinicCardSearch = ({
  isClinicSearch,
  isClickable,
  clinicName,
  clinicCity,
  doctorName,
  onBookAppointment,
  index,
  logo,
  onViewDetails,
}: ClinicCardSearchProps) => {
  return (
    <div className="max-w-md mx-auto bg-white rounded-xl shadow-md overflow-hidden md:max-w-2xl border border-gray-200 hover:border-blue-500 transition duration-300 transform hover:scale-105 m-5">
      <div className="flex justify-between">
        <div className="px-3 py-8">
          {isClinicSearch ? (
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 600 650"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M0 48C0 21.5 21.5 0 48 0H368c26.5 0 48 21.5 48 48V96h50.7c17 0 33.3 6.7 45.3 18.7L589.3 192c12 12 18.7 28.3 18.7 45.3V256v32 64c17.7 0 32 14.3 32 32s-14.3 32-32 32H576c0 53-43 96-96 96s-96-43-96-96H256c0 53-43 96-96 96s-96-43-96-96H48c-26.5 0-48-21.5-48-48V48zM416 256H544V237.3L466.7 160H416v96zM160 464a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm368-48a48 48 0 1 0 -96 0 48 48 0 1 0 96 0zM176 80v48l-48 0c-8.8 0-16 7.2-16 16v32c0 8.8 7.2 16 16 16h48v48c0 8.8 7.2 16 16 16h32c8.8 0 16-7.2 16-16V192h48c8.8 0 16-7.2 16-16V144c0-8.8-7.2-16-16-16H240V80c0-8.8-7.2-16-16-16H192c-8.8 0-16 7.2-16 16z" />
            </svg>
          ) : (
            <svg
              className="w-6 h-6"
              aria-hidden="true"
              fill="currentColor"
              viewBox="0 0 500 500"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M224 256A128 128 0 1 0 224 0a128 128 0 1 0 0 256zm-96 55.2C54 332.9 0 401.3 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7c0-81-54-149.4-128-171.1V362c27.6 7.1 48 32.2 48 62v40c0 8.8-7.2 16-16 16H336c-8.8 0-16-7.2-16-16s7.2-16 16-16V424c0-17.7-14.3-32-32-32s-32 14.3-32 32v24c8.8 0 16 7.2 16 16s-7.2 16-16 16H256c-8.8 0-16-7.2-16-16V424c0-29.8 20.4-54.9 48-62V304.9c-6-.6-12.1-.9-18.3-.9H178.3c-6.2 0-12.3 .3-18.3 .9v65.4c23.1 6.9 40 28.3 40 53.7c0 30.9-25.1 56-56 56s-56-25.1-56-56c0-25.4 16.9-46.8 40-53.7V311.2zM144 448a24 24 0 1 0 0-48 24 24 0 1 0 0 48z" />
            </svg>
          )}

          <div className="w-full max-w-md">
            <h2 className="text-xl font-semibold text-gray-800 mt-3 overflow-hidden whitespace-nowrap overflow-ellipsis">
              {isClinicSearch ? clinicName : doctorName}
            </h2>
            <p className="text-gray-500 mt-2 overflow-hidden whitespace-nowrap overflow-ellipsis">
              {isClinicSearch ? clinicCity : clinicName}
            </p>
          </div>
          {isClickable ? (
            <div className="flex flex-row justify-evenly">
              <button
                onClick={() => onBookAppointment(index)}
                className="block mt-4 text-lg leading-tight font-medium text-blue-500 hover:underline"
              >
                Book Appointment
              </button>
              {!isClinicSearch && (
                <button
                  onClick={() => onViewDetails(index)}
                  className="block mt-4 ml-4 text-lg leading-tight font-medium text-blue-500 hover:underline"
                >
                  View Details
                </button>
              )}
            </div>
          ) : (
            <div className="mt-3 font-lora text-blue-800">
              Doctor is not available today
            </div>
          )}
        </div>
        {logo && (
          <div className="p-8">
            <img
              src={logo}
              alt="Logo"
              className="w-24 h-24 rounded-full animate-spin-slow"
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default ClinicCardSearch;
