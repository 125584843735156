import React, {useState,useEffect} from "react";
import useRedirect from "../../hooks/Redirect";

const Testimonial: React.FC = () => {
  const { redirectTo } = useRedirect();

  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const images = [    
    'https://res.cloudinary.com/ded72ifnt/image/upload/v1713588024/photo1713586952_juikfv.jpg',
    'https://res.cloudinary.com/ded72ifnt/image/upload/v1713588065/photo1713586952_1_ayxvuj.jpg',
    'https://res.cloudinary.com/ded72ifnt/image/upload/v1713592503/photo1713586952_2_yxfhi7.jpg'
    // Add more image URLs as needed
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImageIndex((prevIndex) =>
        prevIndex === images.length - 1 ? 0 : prevIndex + 1
      );
    }, 5000); // Change image every 5 seconds (5000 milliseconds)

    return () => clearInterval(interval);
  }, [images.length]);

  return (
   <section className="bg-white">
  <div className="mx-auto max-w-screen-xl px-4 py-12 sm:px-6 lg:px-8 lg:py-16">
    <h2 className="text-center text-4xl font-bold tracking-tight text-gray-900 sm:text-5xl">
      Read trusted reviews from our customers
    </h2>

    <div className="mt-8 grid grid-cols-1 gap-4 md:grid-cols-3 md:gap-8">
      <blockquote className="rounded-lg bg-gray-50 p-6 shadow-sm sm:p-8">
        <div className="flex items-center gap-4">
          <img
            alt=""
            src="https://ik.imagekit.io/kvsrt5kfk/RCMamorialChildNeurologyandEpilepsyCentre/RC_Mamorial_Child_Neurology_and_Epilepsy_CentreClinicLogo1713021412321_BH71FpxkF"
            className="size-20 rounded-full object-cover"
          />

          <div>            
            <p className="mt-0.5 text-lg font-medium text-gray-900">Dr. Rakesh Sharma</p>
            <p className="mt-0.5 text-lg font-small text-gray-600">Paediatric Neurology</p>
            <p className="mt-0.5 text-lg font-small text-gray-600">RC Mamorial Child Neurology and Epilepsy Centre.</p>
          </div>
        </div>

        <p className="mt-6 text-gray-900">
        "The queue management system provided by Vaidyakram has been a valuable addition to our healthcare facility, enhancing operational efficiency, improving patient experiences, and contributing to better clinical outcomes."
        </p>
      </blockquote>
    </div>
  </div>
  {/* Advertisement 
  <div className="md:col-span-1">
  <a href="https://www.cshospitaljaipur.com/" target="_blank">
    <img
      src={images[currentImageIndex]}
      alt="Advertisement"
      className="rounded-lg shadow-md"
    />
    </a>
  </div>
  */}
</section>


    
    
  );
};

export default Testimonial;
