import React, { useState, useEffect } from "react";
import ClinicDashboardNavBar from "../../components/app/DashboardNavBar/ClinicDashboardNavBar";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { notify } from "../../helper/toast";
import jsPDF from "jspdf";
const SettingPage: React.FC = () => {
  const AxiosInstance = useAxios();
  useEffect(() => {
    getClinicBasicInfo();
  }, []);
  const [showModal, setShowModal] = useState(false);
  const [clinicBasicInfo, setClinicBasicInfo] = React.useState<any>({});
  // Sample data
  const paymentStatus = "Paid";
  const paymentType = "Credit Card";
  const clinicId = localStorage.getItem("clinicId");
  const handleUpload = () => {
    // Handle upload logic
    setShowModal(!showModal);
  };
  const toggleMedia = () => {
    AxiosInstance.post(ApiEndPoints.hideMedia, {
      clinicId: clinicId,
    })
      .then((response: any) => {
        notify(response?.msg, "success");
      })
      .catch((error) => {
        notify(error?.msg, "error");
      })
      .finally(() => {
        setShowModal(!showModal);
      });
  };

  const getClinicBasicInfo = () => {
    AxiosInstance.get(`${ApiEndPoints.clinicBasicInfo}/${clinicId}`)
      .then((response: any) => {
        setClinicBasicInfo(response?.data);
      })
      .catch((error) => {
        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      })
      .finally(() => {});
  };

  const handleDownloadInvoice = () => {
    // Handle download invoice logic
  };
  const handleImageSelect = (event: any) => {
    const selectedFile = event.target.files[0];
    // Do something with the selected file (upload it, etc.)

    // Close the modal after selecting the file
    let formData = new FormData();
    formData.append("media", selectedFile);
    formData.append("type", "image");
    if (clinicId) {
      formData.append("clinicId", clinicId);
    }
    AxiosInstance.post(ApiEndPoints.uploadMedia, formData, {
      headers: {
        "Content-type": "multipart/form-data;",
      },
    })
      .then((response: any) => {
        notify(response?.msg, "success");
      })
      .catch((error) => {
        notify(error?.msg, "error");
      });
    setShowModal(false);
  };
  const handleVideoSelect = (event: any) => {
    const selectedFile = event.target.files[0];
    // Do something with the selected file (upload it, etc.)

    let formData = new FormData();
    formData.append("media", selectedFile);
    formData.append("type", "video");
    if (clinicId) {
      formData.append("clinicId", clinicId);
    }
    AxiosInstance.post(ApiEndPoints.uploadMedia, formData, {
      headers: {
        "Content-type": "multipart/form-data;",
      },
    })
      .then((response: any) => {
        notify(response?.msg, "success");
      })
      .catch((error) => {
        notify(error?.msg, "error");
      });
    // Close the modal after selecting the file
    setShowModal(false);
  };

  const htmlToPdf = async (heading: any, qrcode: any) => {
    try {
      const doc = new jsPDF();

      // Set styles for heading
      doc.setFontSize(18);
      doc.setTextColor(0, 0, 0); // black color
      doc.setFont("lora", "bold"); // Set font and style

      // Calculate the position to center the heading
      const textWidth =
        (doc.getStringUnitWidth(heading) * doc.internal.pageSize.getWidth()) /
        doc.internal.scaleFactor;
      const xOffset = (doc.internal.pageSize.width - textWidth) / 2;

      // Add heading with centered position
      doc.text(heading, 90, 20);

      // Add logo below the heading
      doc.addImage(
        "https://ik.imagekit.io/kvsrt5kfk/vaidyakramlogo.jpg?updatedAt=1712895301007",
        "JPEG",
        75,
        30,
        60,
        80
      ); // Adjust position and size as needed

      // Set styles for QR code
      doc.setFont("lora", "normal"); // Set font and style
      doc.setDrawColor(0); // black border
      doc.setFillColor("255"); // white background
      doc.rect(85, 110, 60, 60, "FD"); // Draw rectangle as background for QR code
      doc.addImage(qrcode, "JPEG", 90, 115, 70, 70); // Add QR code with padding

      // Save PDF
      doc.save(`${heading}.pdf`);
    } catch (error) {
      console.error("Error generating PDF:", error);
      notify("Error generating PDF", "error");
    }
  };

  return (
    <div className="p-4 sm:ml-64">
      <ClinicDashboardNavBar />

      <div className="container mx-auto px-4 py-8">
        <h1 className="text-3xl font-semibold mb-6">Settings</h1>
        <div className="bg-white shadow-md rounded-lg overflow-hidden">
          <div className="p-6">
            <div className="mb-4">
              <p className="mt-1 text-lg text-gray-900">
                {clinicBasicInfo?.name}
              </p>
            </div>
            {/* <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Payment Status
              </label>
              <p className="mt-1 text-lg text-gray-900">{paymentStatus}</p>
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">
                Payment Type
              </label>
              <p className="mt-1 text-lg text-gray-900">{paymentType}</p>
            </div> */}
            <div className="flex justify-between items-center">
              <button
                className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
                onClick={handleUpload}
              >
                Upload Live Status Media
              </button>
              {/* <button
                className="text-gray-700 hover:text-blue-500 font-semibold"
                onClick={handleDownloadInvoice}
              >
                Download Invoice
              </button> */}
            </div>
          </div>
        </div>
      </div>

      {/* Modal for uploading media */}
      {showModal && (
        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            {/* Background overlay */}
            <div
              className="fixed inset-0 transition-opacity"
              aria-hidden="true"
            >
              <div className="absolute inset-0 bg-gray-500 opacity-75"></div>
            </div>

            {/* Modal panel */}
            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            ></span>
            <div className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <div className="sm:flex sm:items-start">
                  <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
                    <h3
                      className="text-lg font-medium leading-6 text-gray-900"
                      id="modal-title"
                    >
                      Upload Media
                    </h3>
                    <div className="mt-2">
                      <input
                        type="file"
                        accept="image/*"
                        onChange={handleImageSelect}
                        className="hidden"
                        id="upload-image"
                      />
                      <label
                        htmlFor="upload-image"
                        className="mb-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-1 sm:text-sm cursor-pointer"
                      >
                        Upload Image
                      </label>
                      <input
                        type="file"
                        accept="video/*"
                        onChange={handleVideoSelect}
                        className="hidden"
                        id="upload-video"
                      />
                      <label
                        htmlFor="upload-video"
                        className="mb-4 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:col-start-1 sm:text-sm cursor-pointer"
                      >
                        Upload Video
                      </label>

                      <button
                        onClick={toggleMedia}
                        className="mt-3 mb-4 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                      >
                        Show/Hide Media
                      </button>

                      <button
                        onClick={handleUpload}
                        className="mt-3 inline-flex justify-center w-full rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:mt-0 sm:col-start-2 sm:text-sm"
                      >
                        Close
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <h1 className="justify-center items-center flex font-lora text-2xl">
        QR Code
      </h1>
      <div className="flex flex-row justify-around mt-10">
        <div
          onClick={() =>
            htmlToPdf("Booking QR Code", clinicBasicInfo?.bookMyDocQrCode)
          }
        >
          <h1 className="text-3xl font-semibold mb-6 font-lora">Booking</h1>
          <img src={clinicBasicInfo?.bookMyDocQrCode} />
        </div>

        <div
          onClick={() =>
            htmlToPdf("Live Status QR Code", clinicBasicInfo?.liveStatusQrCode)
          }
        >
          <h1 className="text-3xl font-semibold mb-6 font-lora">Live Status</h1>
          <img src={clinicBasicInfo?.liveStatusQrCode} />
        </div>
      </div>
    </div>
  );
};

export default SettingPage;
