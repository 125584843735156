import React from "react";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { notify } from "../../helper/toast";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
interface AppointmentModalProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  role: string;
  selectedClinic?: any;
  selectedDoctor?: any;
  tokenGenrate: any;
}

const AppointmentModal: React.FC<AppointmentModalProps> = ({
  modalIsOpen,
  closeModal,
  role,
  selectedClinic,
  selectedDoctor,
  tokenGenrate,
}) => {
  const AxiosInstance = useAxios();
  const [name, setName] = React.useState("");
  const [phoneNumber, setPhoneNumber] = React.useState("");
  const [doctorId, setDoctorId] = React.useState("");
  const [clinicId, setClinicId] = React.useState("");
  const [isloading, setIsLoading] = React.useState(false);
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const resetState = () => {
    setName("");
    setDoctorId("");
    setSelectedDate(new Date());
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (role === "clinic") {
      setIsLoading(true);
      AxiosInstance.post(ApiEndPoints.bookAppointment, {
        doctor: doctorId,
        clinic: selectedClinic?.id,
        name: name,
        date: `${selectedDate.getFullYear()}-${
          selectedDate.getMonth() + 1
        }-${selectedDate.getDate()}`,
      })
        .then((res) => {
          if (res?.data) {
            tokenGenrate(res?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
          closeModal();
          resetState();
        });
    } else {
      setIsLoading(true);
      AxiosInstance.post(ApiEndPoints.bookAppointment, {
        doctor: selectedDoctor?.doctor?.id,
        clinic: selectedDoctor?.clinic?.id,
        name: name,
        date: `${selectedDate.getFullYear()}-${
          selectedDate.getMonth() + 1
        }-${selectedDate.getDate()}`,
      })
        .then((res) => {
          if (res?.data) {
            tokenGenrate(res?.data);
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
          closeModal();
          resetState();
        });
    }
  };
  const checkIsDoctorAvailable = (e: any) => {
    selectedClinic?.doctors.map((val: any) => {
      if (val.id === e && val.isAvailableToday === false) {
        notify("Doctor is not available today", "error");
      } else {
        setDoctorId(e);
      }
    });
  };
  return modalIsOpen ? (
    <div className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center animate-fade-in-down">
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity"></div>
      <div className="relative bg-white rounded-lg overflow-hidden w-full max-w-md mx-auto z-50 shadow-lg">
        <div className="flex justify-between items-center px-4 py-3 bg-gray-100 dark:bg-gray-800 border-b dark:border-gray-700">
          <h3 className="font-bold text-gray-800 dark:text-white">
            Book Appointment
          </h3>
          <button
            className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-white focus:outline-none"
            onClick={closeModal}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-4 space-y-4">
          <form className="space-y-4" onSubmit={handleSubmit}>
            {role === "clinic" ? (
              <div>
                <label className="block text-sm font-medium mb-2 dark:text-black">
                  Clinic Name: {selectedClinic?.name}
                </label>
                <label className="block text-sm font-medium mb-2 dark:text-black">
                  Clinic Address: {selectedClinic?.address}
                </label>
                <select
                  className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
                  value={doctorId}
                  onChange={(e) => {
                    checkIsDoctorAvailable(e.target.value);
                  }}
                >
                  <option value="">Select a doctor</option>
                  {selectedClinic?.doctors.map((doctor: any, index: any) => (
                    <option key={index} value={doctor.id}>
                      {doctor.name}
                    </option>
                  ))}
                </select>
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium mb-2 dark:text-black">
                  Clinic Name: {selectedDoctor?.clinic?.name}
                </label>
                <label className="block text-sm font-medium mb-2 dark:text-black">
                  Doctor Name: {selectedDoctor?.doctor?.name}
                </label>
              </div>
            )}
            <input
              type="text"
              placeholder="Your Name"
              className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            {/* <input
              type="text"
              placeholder="Your Phone Number"
              className="py-3 px-4 block w-full border-gray-200 rounded-lg text-sm focus:border-blue-500 focus:ring-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              required
              
            /> */}
            <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <DatePicker
                selected={selectedDate}
                onChange={(date: Date) => setSelectedDate(date)}
                minDate={new Date()}
                maxDate={new Date(new Date().setDate(new Date().getDate() + 2))}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <p className="mt-2 text-sm text-black-900 font-lora">
              Appointment Date:{" "}
              {`${selectedDate.getDate()}-${
                selectedDate.getMonth() + 1
              }-${selectedDate.getFullYear()}`}
            </p>
            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
              <button
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                onClick={closeModal}
              >
                Close
              </button>
              <button
                type="submit"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-semibold rounded-lg border border-transparent bg-blue-600 text-white hover:bg-blue-700 disabled:opacity-50 disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : null;
};

export default AppointmentModal;
