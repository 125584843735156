import { Chart } from "chart.js";
import React, { useRef } from "react";
import { ApiEndPoints } from "../../constants/api-end-points";
import { useAxios } from "../../config/axios";
import { Bar } from "react-chartjs-2";
import ClinicDashboardNavBar from "../../components/app/DashboardNavBar/ClinicDashboardNavBar";
const WeeklyDoctorReport = () => {
  const AxiosInstance = useAxios();
  const [selectedDoctor, setSelectedDoctor] = React.useState("");
  const [chartData, setChartData] = React.useState<any>([]);
  const [chartLabels, setChartLabels] = React.useState<any>([]);
  const [doctorList, setDoctorList] = React.useState([]);
  const [isDoctorSelected, setIsDoctorSelected] = React.useState(false);
  const chartRef = useRef<HTMLCanvasElement | null>(null);

  React.useEffect(() => {
    getDoctors();
  }, []);

  React.useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext("2d");
      if (ctx) {
        new Chart(ctx, {
          type: "bar",
          data: {
            labels: chartLabels,
            datasets: [
              {
                label: "Token",
                data: [65, 59, 80, 81, 56, 55, 40],
                backgroundColor: "rgb(255, 99, 132)",
                borderColor: "rgb(255, 99, 132)",
                borderWidth: 1,
              },
            ],
          },
          options: {
            responsive: true,
            scales: {
              y: {
                beginAtZero: true,
              },
            },
          },
        });
      }
    }
  }, [chartData, chartLabels]);

  const getData = async (id: any) => {
    const today = new Date();
    const lastSevenDays: string[] = [];
    const chartDataArray: any[] = [];
    const chartLabelArray: string[] = [];
    for (let i = 6; i >= 0; i--) {
      const date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() - i
      );
      lastSevenDays.push(
        new Intl.DateTimeFormat("default", {
          year: "numeric",
          month: "long",
          day: "numeric",
        }).format(date)
      );
    }
    try {
      const response = await AxiosInstance.get(
        `${ApiEndPoints.weeklyReport}/${id}`
      );
      const data = response.data;
      Object.entries(data).forEach(([key, value]) => {
        chartDataArray.push(value);
      });
      chartLabelArray.push(...[...lastSevenDays].reverse());
      console.log(chartDataArray);

      setChartData(chartDataArray);
      setChartLabels(chartLabelArray);
    } catch (error) {
      console.error(error);
    }
  };

  const getDoctors = async () => {
    try {
      const response = await AxiosInstance.get(
        `${ApiEndPoints.getBookingDoctos}/${localStorage.getItem("clinicId")}`
      );
      setDoctorList(response.data.doctors);
    } catch (error) {
      console.error(error);
    }
  };

  const handleDoctorSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    console.log(event.target.value);
    if (event.target.value === "") return;
    setSelectedDoctor(event.target.value);
    setIsDoctorSelected(true);
    getData(event.target.value);
  };

  return (
    <div className="p-4 sm:ml-64">
      <ClinicDashboardNavBar />
      <div className="container mx-auto p-4">
        <h1 className="text-2xl font-bold font-lora mb-6">
          Weekly Report Dashboard
        </h1>
        <div className="w-2/2">
          <label htmlFor="doctor" className="block font-medium text-gray-700">
            Select Doctor:
          </label>
          <select
            id="doctor"
            className={`block w-full mt-1 border ${
              isDoctorSelected ? "border-blue-500" : "border-blue-500"
            } rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2`}
            value={selectedDoctor}
            onChange={handleDoctorSelect}
            required
          >
            <option value="">Select Doctor</option>
            {doctorList.map((doctor: any) => (
              <option key={doctor.id} value={doctor.id}>
                {doctor.name}
              </option>
            ))}
          </select>
        </div>
        <div className="relative h-96 w-full">
          <Bar
            data={{
              labels: chartLabels,
              datasets: [
                {
                  label: "Token",
                  data: chartData,
                  borderWidth: 1,
                },
              ],
            }}
          />
          {/* <canvas ref={chartRef} className="absolute inset-0"></canvas> */}
        </div>
      </div>
    </div>
  );
};

export default WeeklyDoctorReport;
