import { useState, useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import LandingPage from "../pages/Landing/Landing";
import LoginPage from "../pages/Login/Login";
import SignupPage from "../pages/Signup/Signup";
import DoctorLogin from "../pages/DoctorLogin/DoctorLogin";
import ClinicDashboard from "../pages/ClinicDashboard/ClinicDashboard";
import DoctorDashboard from "../pages/DoctorDashboard/DoctorDashboard";
import ClinicAppointment from "../pages/ClinicAppointment/ClinicAppointment";
import NotFoundPage from "../pages/NotFound/NotFound";
import AddDoctor from "../pages/AddDoctor/AddDoctor";
import Help from "../pages/Help/Help";
import GlobalSideBar from "../components/GlobalSideBar/GlobalSideBar";
import Search from "../pages/Search/Search";
import ClinicLiveStatusForPatients from "../pages/ClinicLiveStatusForPatients/ClinicLiveStatusForPatients";
import BookMyAppointment from "../pages/BookMyAppointment/BookMyAppointment";
import SettingPage from "../pages/Setting/Setting";
import ClinicLiveStatus from "../pages/ClinicLiveStatus/ClinicLiveStatus";
import WeeklyDoctorReport from "../pages/Report/WeeklyDoctorReport";
const AppRouter: React.FC = () => {
  const [userToken, setUsetToken] = useState("");
  const [userRole, setUserRole] = useState(""); // 'clinic' or 'doctor'
  useEffect(() => {
    const token = localStorage.getItem("token");
    const role = localStorage.getItem("role");

    if (token && role) {
      setUsetToken(token);
      setUserRole(role);
    } else {
      setUsetToken("");
      setUserRole("");
    }
  }, [userToken, userRole]);

  // Function to handle login
  const handleLogin = (role: string, token: string) => {
    setUserRole(role);
    setUsetToken(token);
    localStorage.setItem("role", role); // Store role in local storage
  };

  return (
    <Router>
      {userToken != "" && userRole === "clinic" ? (
        <>
          {/* <GlobalSideBar> */}
          <Routes>
            <Route path="/" element={<ClinicDashboard />} />
            <Route
              path="/login"
              element={
                userToken != "" ? (
                  <LoginPage onLogin={handleLogin} />
                ) : (
                  <ClinicDashboard />
                )
              }
            />
            <Route path="/clinicDashboard" element={<ClinicDashboard />} />
            <Route path="/addDoctor" element={<AddDoctor />} />
            <Route path="/help" element={<Help />} />
            <Route path="/setting" element={<SettingPage />} />
            <Route path="/weeklyReport" element={<WeeklyDoctorReport />} />
          </Routes>
          {/* </GlobalSideBar> */}
        </>
      ) : userToken != "" && userRole === "doctor" ? (
        <>
          <Routes>
            <Route
              path="/login"
              element={
                userToken != "" ? (
                  <LoginPage onLogin={handleLogin} />
                ) : (
                  <DoctorDashboard />
                )
              }
            />
            <Route path="/" element={<DoctorDashboard />} />
            <Route path="/doctorDashboard" element={<DoctorDashboard />} />
            <Route path="/help" element={<Help />} />
          </Routes>
        </>
      ) : (
        <>
          <Routes>
            <Route path="/" element={<LandingPage />} />
            <Route
              path="/login"
              element={<LoginPage onLogin={handleLogin} />}
            />
            <Route
              path="/signup-vaidya"
              element={<SignupPage onSignup={handleLogin} />}
            />
            <Route
              path="/doctor-login"
              element={<DoctorLogin onLogin={handleLogin} />}
            />
            <Route path="/search" element={<Search />} />
            <Route
              path="/live-status/:clinicId"
              element={<ClinicLiveStatusForPatients />}
            />
            <Route
              path="/live-status-clinic/:clinicId"
              element={<ClinicLiveStatus />}
            />
            <Route
              path="/book-my-appointment/:clinicId"
              element={<BookMyAppointment />}
            />
            <Route path="/help" element={<Help />} />
            <Route path="*" element={<NotFoundPage />} />
          </Routes>
        </>
      )}
    </Router>
  );
};

export default AppRouter;
