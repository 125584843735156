import React, { FC, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { socketInit } from "../../helper/socket";
import eventEmitter from "../../helper/eventEmitter";
import ReactPlayer from "react-player/lazy";
const ClinicLiveStatusForPatients: FC = ({}) => {
  const AxiosInstance = useAxios();
  const { clinicId } = useParams<{ clinicId: string }>();
  const [clinic, setClinic] = React.useState<any>(null);
  const [clinicName, setClinicName] = React.useState<any>("");
  const [doctors, setDoctors] = React.useState<any>([]);
  const [toggleMedia, setToggleMedia] = React.useState<boolean>(true);
  const [media, setMedia] = React.useState<any>(null);
  const [mediaType, setMediaType] = React.useState<any>(null);
  const [startVideo, setStartVideo] = React.useState<boolean>(false);
  useEffect(() => {
    setClinic(clinicId);
    getCurrentTokenStatus();
    socketInit(clinicId);
    eventEmitter.on("currentTokenNumber", liveTokenStatus);
    eventEmitter.on("toggleMediaStatus", toggleMediaStatus);
    eventEmitter.on("clinicMediaStatus", mediaStatus);
  }, []);

  const liveTokenStatus = (data: any) => {
    setDoctors(data?.doctors);
    setToggleMedia(data?.toggleMedia);
    setMediaType(data?.mediaType);
    setMedia(data?.media);
  };
  const toggleMediaStatus = (data: any) => {
    setToggleMedia(data?.toggleMedia);
  };
  const mediaStatus = (data: any) => {
    setMediaType(data?.mediaType);
    setMedia(data?.media);
  };
  const getCurrentTokenStatus = () => {
    AxiosInstance.get(
      `${ApiEndPoints.getLiveStatus}/${clinic ? clinic : clinicId}`
    )
      .then((res: any) => {
        setClinicName(res?.data?.clinicName);
        setDoctors(res?.data?.doctors);
        setToggleMedia(res?.data?.toggleMedia);
        setMediaType(res?.data?.mediaType);
        setMedia(res?.data?.media);
      })
      .catch((error) => console.log(error))
      .finally(() => console.log("finally"));
  };
  return (
    <div className="min-h-screen bg-white font-sans text-gray-900 px-4 py-6 sm:px-6 lg:px-8 animate-fade-in-down">
      <h1 className="text-2xl font-semibold text-center mb-6">{clinicName}</h1>
      <div className="flex flex-wrap justify-between">
        <div className="overflow-x-auto md:w-1/2 md:pr-4">
          <div className="align-middle inline-block min-w-full">
            <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table className="min-w-full divide-y divide-gray-200 border border-gray-300 rounded-lg">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Doctor Name
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                    >
                      Current Token Status
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {doctors.map((doctor: any, i: any) => (
                    <tr key={i}>
                      <td className="px-6 py-4 whitespace-nowrap text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl font-medium text-gray-900">
                        {doctor?.doctorName}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-sm sm:text-base md:text-lg lg:text-xl xl:text-2xl text-black-900">
                        {doctor?.currentToken}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
        {toggleMedia && (
          <div className="w-full md:w-1/2 md:pl-4">
            {mediaType == "image" ? (
              <img src={media} alt="Clinic media" className="w-full h-auto" />
            ) : (
              <div style={{ position: "relative", paddingTop: "56.25%" }}>
                <ReactPlayer
                  onReady={() => setStartVideo(true)}
                  url={media}
                  loop={true}
                  playing={startVideo}
                  show={true}
                  controls={true}
                  muted={true}
                  style={{ position: "absolute", top: 20, left: -10 }}
                  width="100%"
                  height="100%"
                />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ClinicLiveStatusForPatients;
