import React, { useState } from "react";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { notify } from "../../helper/toast";
interface ModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
  isSuccess: any;
}
interface Option {
  label: string;
  value: string;
}
function AddDoctorModal({ showModal, setShowModal, isSuccess }: ModalProps) {
  const AxiosInstance = useAxios();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [name, setName] = useState("");
  const [address, setAddress] = useState("");
  const [city, setCity] = useState("");
  const [specialty, setSpecialty] = useState("");
  const [clinicId, setClinicId] = useState(localStorage.getItem("clinicId"));
  const [options, setOptions] = useState<Option[]>([]);
  React.useEffect(() => {
    doctorSpecialization();
  }, []);
  const doctorSpecialization = () => {
    AxiosInstance.get(ApiEndPoints.specialization, {})
      .then((response: any) => {
        if (response.status) {
          const formattedData: Option[] = response.data.map((item: any) => ({
            label: item.name,
            value: item.id,
          }));
          setOptions(formattedData);
        } else {
          console.error("Failed to fetch specializations:", response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {});
  };
  const handleSubmit = (event: any) => {
    event.preventDefault();
    const docSpecialty: any = [];
    docSpecialty.push(specialty);

    AxiosInstance.post(ApiEndPoints.doctorReg, {
      email,
      password,
      name,
      address,
      city,
      speciality: docSpecialty,
      clinic: clinicId,
    })
      .then((response: any) => {
        isSuccess(response?.id);
      })
      .catch((error) => {
        console.log(error);

        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      })
      .finally(() => {
        setShowModal(!showModal);
        setSpecialty("");
      });
  };

  return (
    <div className={`max-w-2xl mx-auto ${showModal ? "" : "hidden"} `}>
      {showModal && (
        <div
          id="authentication-modal"
          aria-hidden="true"
          className={`${
            showModal ? "" : "hidden"
          } overflow-x-hidden overflow-y-auto fixed inset-0 flex  justify-center items-center z-50`}
        >
          <div
            className="relative w-full max-w-md px-4 h-full md:h-auto m-10"
            style={{ marginTop: "10%" }}
          >
            <div className="bg-white rounded-lg shadow relative dark:bg-gray-700">
              <div>
                <button
                  onClick={() => setShowModal(false)}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </div>
              <form
                className="space-y-6 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8"
                onSubmit={handleSubmit}
              >
                <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                  Add Your Doctor Details
                </h3>

                <div>
                  <label
                    htmlFor="name"
                    className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                  >
                    Name
                  </label>
                  <input
                    onChange={(e) => setName(e.target.value)}
                    type="name"
                    name="name"
                    id="name"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    placeholder="Govind Maheshwari"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="email"
                    className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                  >
                    Email
                  </label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    type="email"
                    name="email"
                    id="email"
                    placeholder="govind@gmail.com"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    required
                  />
                </div>

                <div>
                  <label
                    htmlFor="password"
                    className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                  >
                    Password
                  </label>
                  <input
                    onChange={(e) => setPassword(e.target.value)}
                    type="password"
                    name="password"
                    id="password"
                    placeholder="••••••••"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="address"
                    className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                  >
                    Address
                  </label>
                  <input
                    onChange={(e) => setAddress(e.target.value)}
                    type="address"
                    name="address"
                    id="address"
                    placeholder="Vaishali Nagar, Jaipur"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    required
                  />
                </div>
                <div>
                  <label
                    htmlFor="city"
                    className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                  >
                    City
                  </label>
                  <input
                    onChange={(e) => setCity(e.target.value)}
                    type="city"
                    name="city"
                    id="city"
                    placeholder="Jaipur"
                    className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    required
                  />
                </div>

                <div className="form-group">
                  <label
                    htmlFor="specialty"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Specialty
                  </label>
                  <select
                    id="specialty"
                    value={specialty}
                    required
                    onChange={(e) => setSpecialty(e.target.value)}
                    className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                  >
                    <option value="">Select a specialty</option>
                    {options.map((specialty) => (
                      <option key={specialty.value} value={specialty.value}>
                        {specialty.label}
                      </option>
                    ))}
                  </select>
                </div>

                <button
                  type="submit"
                  className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                >
                  Add Doctor
                </button>
              </form>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddDoctorModal;
