import React, { useState, useEffect } from "react";
import { notify } from "../../helper/toast";
import DoctorDashboardNavBar from "../../components/app/DashboardNavBar/DoctorDashboardNavBar";
import ClinicDashboardNavBar from "../../components/app/DashboardNavBar/ClinicDashboardNavBar";

interface ModalProps {
  showModal: boolean;
  setShowModal: (value: boolean) => void;
}

interface Option {
  label: string;
  value: string;
}

function ModalComponent({ showModal, setShowModal }: ModalProps) {
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [clinicNameOnChange, setClinicNameOnChange] = useState("");
  const [issueType, setIssueType] = useState("");
  const [description, setDescription] = useState("");
  const [clinicName, setClinicName] = React.useState(localStorage.getItem("clinicName"));
  const [doctorName, setDoctorName] = React.useState(localStorage.getItem("doctorName"));

  const IssueTypeList = [
    { label: "Forgot your password", value: "Forgot your password" },
    { label: "Token not working", value: "Token not working" },
    { label: "Other", value: "Other" },
  ];

  

  const handleSubmit = (event: any) => {
    event.preventDefault(); // Prevent the default form submission behavior
    
    // Perform validation
    if (!name || !email || !description) {
      notify("Please fill in all required fields.", "error");
      return;
    }

    setShowModal(false);
    notify("Your request was successfully created.", "success");
  };

  return (
    <div className={`max-w-2xl mx-auto ${showModal ? "" : "hidden"} `}>
      {showModal && (
        <div
          id="authentication-modal"
          aria-hidden="true"
          className={`${
            showModal ? "" : "hidden"
          } overflow-x-hidden overflow-y-auto fixed inset-0 flex  justify-center items-center z-50`}
        >
          <div className="relative w-full max-w-md px-4 h-full md:h-auto m-10">
            <div className="bg-white rounded-lg shadow relative dark:bg-gray-700">
              <div>
                {/* Close button */}
                <button
                  onClick={() => setShowModal(false)}
                  className="absolute top-4 right-4 text-gray-500 hover:text-gray-700 focus:outline-none"
                >
                  <svg
                    className="w-6 h-6"
                    fill="none"
                    stroke="currentColor"
                    viewBox="0 0 24 24"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
                
                {/* Dashboard nav bar */}
                {clinicName ? <ClinicDashboardNavBar /> : ""}
                {doctorName ? <DoctorDashboardNavBar /> : ""}

                {/* Form */}
                <form
                  id="web2leadForm"
                  action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
                  method="POST"
                  className="space-y-6 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8"
                >
                  <input type="hidden" name="orgid" value="00D2w00000RqUJN"></input>
                  <input type="hidden" name="retURL" id="retURL" value="ride360.in" /> 
                  <input type="hidden" name="Doctor_Name__c" value={doctorName ? doctorName : ""}></input>

                  <h3 className="text-xl font-medium text-gray-900 dark:text-white">
                    Drop Us a Line
                  </h3>

                  {!clinicName && !doctorName && (
                    <div>
                      <label
                        htmlFor="clinicname"
                        className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                      >
                        Clinic Name
                      </label>
                      <input
                        type="Clinic_Name__c"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                        name="Clinic_Name__c"
                        value={clinicName ? clinicName : clinicNameOnChange}
                        onChange={(e) => setClinicNameOnChange(e.target.value)}
                        placeholder="XYZ Hospital"
                        required
                      ></input>
                    </div>
                  )}

                  <div>
                    <label
                      htmlFor="name"
                      className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                    >
                      Name
                    </label>
                    <input
                      onChange={(e) => setName(e.target.value)}
                      type="text"
                      name="name"
                      id="name"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Adam Bristh"
                      required
                    />
                  </div>

                  <div>
                    <label
                      htmlFor="email"
                      className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                    >
                      Email
                    </label>
                    <input
                      onChange={(e) => setEmail(e.target.value)}
                      type="email"
                      name="email"
                      id="email"
                      placeholder="AdamBristh@gmail.com"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                    />
                  </div>

                  <div className="form-group">
                    <label htmlFor="issue" className="block text-sm font-medium text-gray-700">
                      Issue Type
                    </label>
                    <select
                      id="Issue_Type__c"
                      name="Issue_Type__c"
                      value={issueType}
                      onChange={(e) => setIssueType(e.target.value)}
                      className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      required
                    >
                      <option value="">Select a Reason</option>
                      {IssueTypeList.map((issueType) => (
                        <option key={issueType.value} value={issueType.value}>
                          {issueType.label}
                        </option>
                      ))}
                    </select>
                  </div>

                  <div>
                    <label
                      htmlFor="Comment"
                      className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
                    >
                      Comment
                    </label>
                    <textarea
                      onChange={(e) => setDescription(e.target.value)}
                      name="description"
                      id="description"
                      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      placeholder="Add your comment."
                      required
                    ></textarea>
                  </div>

                  <button
                    type="submit"
                    className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                  >
                    Submit
                  </button>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default ModalComponent;
