import React, { useEffect, useState } from "react";
import ClinicDashboardNavBar from "../../components/app/DashboardNavBar/ClinicDashboardNavBar";
import AddDoctorModal from "../../components/widget/AddDoctorModal";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { notify } from "../../helper/toast";
import LoadingSpinner from "../../helper/LoadingSpinner";
import DoctorCard from "./Doctor";
import DoctorAction from "./DoctorAction";
import AddDaysModal from "../../components/widget/AddDaysModal";

const AddDoctor: React.FC = () => {
  const AxiosInstance = useAxios();
  const [showModal, setShowModal] = useState(false);

  const [doctorList, setDoctorList] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [clinicId, setClinicId] = useState(localStorage.getItem("clinicId"));
  const [openDoctorAction, setOpenDoctorAction] = useState(false);
  const [selectedDoctorId, setSelectedDoctorId] = useState<any>("");
  const [showDaysModal, setShowDaysModal] = useState(false);
  useEffect(() => {
    getDoctorList();
  }, []);
  const getDoctorList = () => {
    setLoading(true);
    AxiosInstance.get(`${ApiEndPoints.getDoctorList}/${clinicId}`)
      .then((response: any) => {
        setDoctorList(response?.data);
      })
      .catch((error) => {
        console.log(error);

        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const updateDoctorTimeSlote = (data: any) => {
    AxiosInstance.post(`${ApiEndPoints.updateDoctorTimeSlot}`, {
      doctorId: selectedDoctorId,
      timeSlots: data,
    })
      .then((response: any) => {
        console.log(response);
        setShowDaysModal(false);
        notify("Doctor Added Successfully", "success");
        getDoctorList();
      })
      .catch((error) => {
        setShowDaysModal(false);
        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      });
  };

  return (
    <div className="p-4 sm:ml-64">
      <ClinicDashboardNavBar />
      <div className="flex items-center justify-center flex-col">
        <button
          className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4"
          onClick={() => setShowModal(true)}
        >
          Add Doctor
        </button>
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div className="mb-4 mt-4 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 ">
            {doctorList.length > 0 &&
              doctorList.map((doctor) => (
                <DoctorCard
                  key={doctor?.id}
                  name={doctor?.name}
                  email={doctor?.email}
                  city={doctor?.city}
                  address={doctor?.address}
                  onClick={() => {
                    setOpenDoctorAction(true);
                    setSelectedDoctorId(doctor?.id);
                  }}
                />
              ))}
          </div>
        )}
      </div>
      <AddDoctorModal
        showModal={showModal}
        setShowModal={(e) => setShowModal(e)}
        isSuccess={(e: any) => {
          setSelectedDoctorId(e);
          setShowDaysModal(true);
        }}
      />
      <DoctorAction
        openModal={openDoctorAction}
        closeModal={() => setOpenDoctorAction(false)}
        doctorId={selectedDoctorId}
        doctorDelete={() => getDoctorList()}
      />
      <AddDaysModal
        showModal={showDaysModal}
        submitDays={(e: any) => {
          updateDoctorTimeSlote(e);
        }}
      />
      {!loading && doctorList.length === 0 && (
        <div
          className="flex items-center justify-center"
          style={{ minHeight: "100px" }}
        >
          <img
            className="w-full"
            src="https://ik.imagekit.io/kvsrt5kfk/undraw_No_data_re_kwbl.png?updatedAt=1712911348783"
            alt=""
          />
        </div>
      )}
    </div>
  );
};

export default AddDoctor;
