import React, { useEffect } from "react";
import { useAxios } from "../../config/axios";
import LoadingSpinner from "../../helper/LoadingSpinner";
import { notify } from "../../helper/toast";
import ClinicCardSearch from "../../components/widget/ClinicCardSearch";
import ReactGA from "react-ga4";
import AppointmentModal from "./AppointmentModal";
import moment from "moment";
import { toPng } from "html-to-image";
import download from "downloadjs";
import DetailsModel from "./DetailsModel";
const Search = () => {
  const AxiosInstance = useAxios();
  const ref = React.useRef<HTMLDivElement>(null);
  const [search, setSearch] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const [date, setDate] = React.useState<any>([]);
  const [role, setRole] = React.useState("");
  const [modalIsOpen, setModalIsOpen] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState("");
  const [successModal, setSuccessModal] = React.useState(false);
  const [gernatedToken, setGernatedToken] = React.useState<any>("");
  const [detailsModalIsOpen, setDetailsModalIsOpen] = React.useState(false);
  useEffect(() => {
    ReactGA.event({
      category: "Search Page Visits",
      action: "Search Page View",
    });
  }, []);
  const handleSearch = (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);
    AxiosInstance.get(`/search?query=${search}&page=1&limit=10`)
      .then((res: any) => {
        setDate(res?.data?.result);
        setRole(res?.data?.role);
      })
      .catch((error) => {
        setDate([]);
        setRole("");
        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };
  const onBookAppointment = (index: number) => {
    openModal();
    setSelectedData(date[index]);
  };
  const onViewDetails = (index: number) => {
    setDetailsModalIsOpen(true);
    setSelectedData(date[index]);
  };
  const showSuccessModal = (e: any) => {
    setGernatedToken(e);

    setSuccessModal(true);
    setTimeout(() => {
      convertTokenToImage();
    }, 1000);
  };

  const formatDate = (dateString:any) => {
    const date = new Date(dateString);
    return date.toLocaleDateString("en-US");
  };
  const convertTokenToImage = React.useCallback(async () => {
    if (ref.current === null) {
      return;
    }

    const imgBase64_dump = await toPng(ref.current, {
      quality: 0.01,
      pixelRatio: 1,
      height: ref.current.scrollHeight,
      canvasWidth: ref.current.scrollWidth,
      canvasHeight: ref.current.scrollHeight,
      style: { backgroundColor: "transparent" },
    });

    const imgBase64_again = await toPng(ref.current, {
      quality: 1,
      pixelRatio: 1,
      height: ref.current.scrollHeight,
      canvasWidth: ref.current.scrollWidth * 2,
      canvasHeight: ref.current.scrollHeight * 2,
      style: { backgroundColor: "transparent" },
    });
    download(imgBase64_again, "appointment.png", "image/png");
  }, [ref]);
  return (
    <div className="mt-10 justify-center items-center px-4 sm:px-0">
      <form className="max-w-md mx-auto" onSubmit={handleSearch}>
        <label className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">
          Search
        </label>
        <div className="relative">
          <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
            <svg
              className="w-4 h-4 text-gray-500 dark:text-gray-400"
              aria-hidden="true"
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 20 20"
            >
              <path
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="m19 19-4-4m0-7A7 7 0 1 1 1 8a7 7 0 0 1 14 0Z"
              />
            </svg>
          </div>
          <input
            onChange={(e) => setSearch(e.target.value)}
            type="search"
            id="default-search"
            className="block w-full p-4 ps-10 text-sm text-black border border-black rounded-lg bg-white focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500"
            placeholder="Search Doctors, Clinics, Hospitals"
            required
          />
          <button
            type="submit"
            className="text-white absolute end-2.5 bottom-2.5 bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-4 py-2 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
          >
            Search
          </button>
        </div>
      </form>
      {loading ? (
        <LoadingSpinner />
      ) : (
        <div className="mt-10">
          {date && date.length > 0 ? (
            date.map((res: any, index: any) => (
              <ClinicCardSearch
                index={index}
                onBookAppointment={(e: any) => onBookAppointment(e)}
                key={index}
                isClinicSearch={role === "clinic" ? true : false}
                isClickable={
                  res?.doctor?.isAvailableToday == null
                    ? true
                    : res?.doctor?.isAvailableToday == true
                    ? true
                    : false
                }
                clinicName={role === "clinic" ? res?.name : res?.clinic?.name}
                clinicCity={res.address}
                doctorName={res?.doctor?.name}
                logo={res?.logo}
                onViewDetails={(e: any) => onViewDetails(e)}
              />
            ))
          ) : (
            <div>
              <p className="text-center text-gray-500 dark:text-gray-400 mt-32">
                Search for doctors, clinics, or hospitals and book your
                appointment.
              </p>
            </div>
          )}
        </div>
      )}

      {successModal && (
        <div
          id="my-token-details"
          ref={ref}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <div className="fixed inset-0 bg-black opacity-50"></div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 scale-100 animate-fade-in-down">
            <div className="px-4 py-2 flex justify-between items-center">
              <h2 className="text-xl font-bold text-gray-800">
                Your Token Details
              </h2>
              <button onClick={() => setSuccessModal(false)}>
                <svg
                  className="h-5 w-5 text-gray-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="p-4">
              <div className="bg-yellow-500 text-gray-700 rounded-md px-4 py-2 mb-4">
                <span className="font-bold text-lg">Important!</span> This token
                is valid for, {moment(gernatedToken?.date).format("DD-MM-YYYY")}
                . If you are unable to attend your appointment, you will need to
                generate a new token again. Please take screenshot of this token
                for future
              </div>

              <p className="mb-4  text-gray-700 font-bold text-lg">
                Your Token Number Is : {gernatedToken?.token}
              </p>
              <p className="mb-4  text-gray-700 font-bold text-lg">
                Your Name : {gernatedToken?.name}
              </p>
              <p className="mb-4  text-gray-700 font-bold text-lg">
                Clinic Name : {gernatedToken?.clinic}
              </p>
              <p className="mb-4  text-gray-700 font-bold text-lg">
                Doctor Name : {gernatedToken?.doctor}
              </p>
              <p className="mb-4  text-gray-700 font-bold text-lg">
                Apppointment Date : {formatDate(gernatedToken?.date)}
              </p>

              <button
                className="mb-4 py-2 px-4 bg-green-500 text-white rounded block"
                onClick={() => {
                  window.open(
                    "https://vaidyakram.in/live-status/" +
                      gernatedToken.clinicId,
                    "_blank"
                  );
                }}
              >
                Live Status
              </button>
              <button
                onClick={() => setSuccessModal(false)}
                className="mb-4 py-2 px-4 bg-green-500 text-white rounded block"
              >
                Thanks! for appointment
              </button>
            </div>
          </div>
        </div>
      )}
      <AppointmentModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        role={role}
        selectedClinic={selectedData}
        selectedDoctor={selectedData}
        tokenGenrate={(e: any) => showSuccessModal(e)}
      />
      <DetailsModel
        modalIsOpen={detailsModalIsOpen}
        role={role}
        closeModal={() => setDetailsModalIsOpen(false)}
        data={selectedData}
      />
    </div>
  );
};

export default Search;
