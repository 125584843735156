export const ApiEndPoints = {
  clinicLogin: "clinic-login",
  clinicReg: "clinic-register",
  getDoctorList: "get-doctor-by-clinic-id",
  getAllDoctorToken: "get-all-doctor-token",
  doctorReg: "doctor-register",
  doctorLogin: "doctor-login",
  getDoctorTokenForToday: "get-doctor-token-for-today",
  nextUserToken: "next-user",
  specialization: "get-speciality",
  bookAppointment: "book-appointment",
  getLiveStatus: "get-live-status-clinic",
  getBookingDoctos: "get-booking-doctors",
  updateDoctorPassword: "update-doctor-password",
  deleteDoctor: "doctor-delete",
  leadSupport: "lead-register",
  clinicBasicInfo: "clinic-basic-info",
  isDoctorAvailable: "doctor-today-Available",
  uploadMedia: "upload-clinic-media",
  hideMedia: "toggle-media-visibility",
  updateDoctorTimeSlot: "update-doctor-time-slote",
  getDoctorPatientsList: "get-today-appointments",
  weeklyReport: "weekly-report",
};
