import React from "react";
import { Chart as ChartJS, registerables } from 'chart.js';
import { Doughnut, Chart } from 'react-chartjs-2'
ChartJS.register(...registerables);

interface ModalProps {
    doctorData: any;
}
const DoctorTokenChart = ({ doctorData}: ModalProps) => {
  // Extract doctor names and token counts from the data
  const doctorNames = doctorData.map((data:any) => data.doctorName);
  const tokenCounts = doctorData.map((data:any) => data.totalToken);

  // Chart data
  const data = {
    labels: doctorNames,
    datasets: [
      {
        label: "Tokens Received",
        data: tokenCounts,
        backgroundColor: [
          "rgba(255, 99, 132, 0.6)",
          "rgba(54, 162, 235, 0.6)",
          "rgba(255, 206, 86, 0.6)",
          "rgba(75, 192, 192, 0.6)",
          "rgba(153, 102, 255, 0.6)",
          "rgba(255, 159, 64, 0.6)",
        ],
      },
    ],
  };

  // Chart options
  const options = {
    indexAxis: 'y',
    elements: {
      bar: {
        borderWidth: 2,
      },
    },
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: 'Doctor Token Chart',
      },
    },
  };

  return (
    <div style={{width: "30%", margin: "20px auto"}}>
     <h2><b>Doctor's Daily Token's</b> </h2>
      <Doughnut data={data} />
    </div>
  );
};

export default DoctorTokenChart;
