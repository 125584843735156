import React, { useState } from "react";
import { motion } from "framer-motion";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { notify } from "../../helper/toast";

interface DoctorActionProps {
  openModal: boolean;
  closeModal: any;
  doctorId: string;
  doctorDelete: any;
}
const DoctorAction = ({
  openModal,
  closeModal,
  doctorId,
  doctorDelete,
}: DoctorActionProps) => {
  const AxiosInstance = useAxios();
  const [password, setPassword] = useState("");
  const updatePassword = () => {
    AxiosInstance.post(ApiEndPoints.updateDoctorPassword, {
      doctorId: doctorId,
      password: password,
    })
      .then((response) => {
        notify("Password updated successfully", "success");
        closeModal();
      })
      .catch((error) => {
        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      });
  };

  const deleteDoctor = () => {
    AxiosInstance.post(ApiEndPoints.deleteDoctor, {
      id: doctorId,
    })
      .then((response) => {
        notify("Doctor deleted successfully", "success");
        closeModal();
        doctorDelete();
      })
      .catch((error) => {
        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      });
  };
  return (
    <div>
      {openModal && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          className="fixed z-50 inset-0 overflow-y-auto" // Increase z-index value to 50
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <motion.div
              initial={{ scale: 0 }}
              animate={{ scale: 1 }}
              className="inline-block align-bottom bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full"
            >
              <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                <h3 className="text-lg leading-6 font-medium text-gray-900">
                  Update Doctor Password
                </h3>
                <input
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  type="text"
                  className="mt-2 border rounded-lg p-2 w-full"
                  placeholder="New Password"
                />
              </div>
              <div className="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => {
                    if (
                      window.confirm(
                        "Are you sure you want to delete the doctor?"
                      )
                    ) {
                      deleteDoctor();
                    }
                  }}
                >
                  Delete Doctor
                </button>
                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-green text-base font-medium text-gray-700 hover:bg-green-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => updatePassword()}
                >
                  Update Password
                </button>

                <button
                  type="button"
                  className="mt-3 w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
                  onClick={() => closeModal()}
                >
                  Close
                </button>
              </div>
            </motion.div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default DoctorAction;
