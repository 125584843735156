import React from "react";
interface DetailsModelProps {
  modalIsOpen: boolean;
  closeModal: () => void;
  role: string;
  data?: any;
}
const DetailsModel = ({
  modalIsOpen,
  closeModal,
  role,
  data,
}: DetailsModelProps) => {
  return modalIsOpen ? (
    <div className="fixed inset-0 overflow-y-auto z-50 flex items-center justify-center animate-fade-in-down">
      <div className="fixed inset-0 bg-gray-900 bg-opacity-50 transition-opacity"></div>
      <div className="relative bg-white rounded-lg overflow-hidden w-full max-w-md mx-auto z-50 shadow-lg">
        <div className="flex justify-between items-center px-4 py-3 bg-gray-100 dark:bg-gray-800 border-b dark:border-gray-700">
          <h3 className="font-bold text-gray-800 dark:text-white">Details</h3>
          <button
            className="text-gray-600 hover:text-gray-800 dark:text-gray-400 dark:hover:text-white focus:outline-none"
            onClick={closeModal}
          >
            <svg
              className="w-6 h-6"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <div className="p-4 space-y-4">
          <form className="space-y-4">
            {role === "clinic" ? (
              <div>
                <label className="block text-sm font-medium mb-2 dark:text-black">
                  Clinic Name: {data?.name}
                </label>
                <label className="block text-sm font-medium mb-2 dark:text-black">
                  Clinic Address: {data?.address}
                </label>
              </div>
            ) : (
              <div>
                <label className="block text-sm font-medium mb-2 dark:text-black">
                  Clinic Name: {data?.clinic?.name}
                </label>
                <label className="block text-sm font-medium mb-2 dark:text-black">
                  Doctor Name: {data?.doctor?.name}
                </label>

                {data &&
                  data?.doctor?.speciality &&
                  data?.doctor?.speciality.map((val: any) => {
                    return (
                      <label
                        key={val}
                        className="block text-sm font-medium mb-2 dark:text-black"
                      >
                        Speciality: {val} ,{" "}
                      </label>
                    );
                  })}
                  
                 
                  {data && data?.doctor?.timeSlots?.length > 0 && (
                    <div className="flex flex-row justify-between">
                      <label className="block text-sm font-medium mb-2 dark:text-black">
                        Days
                      </label>
                      <label className="block text-sm font-medium mb-2 dark:text-black">
                        Start Time - End Time
                      </label>
                    </div>
                  )}




                {data &&
                  data?.doctor?.timeSlots &&
                  data?.doctor?.timeSlots.map((val: any) => {
                    return (
                      <div className="flex flex-row justify-between">
                        <label
                          key={val}
                          className="block text-sm font-medium mb-2 dark:text-black"
                        >
                          {val.dayOfWeek}
                        </label>
                        <label
                          key={val}
                          className="block text-sm font-medium mb-2 dark:text-black"
                        >
                          {val.slots[0]?.startTime} - {val.slots[0]?.endTime}
                        </label>
                      </div>
                    );
                  })}
              </div>
            )}

            <div className="flex justify-end items-center gap-x-2 py-3 px-4 border-t dark:border-gray-700">
              <button
                type="button"
                className="py-2 px-3 inline-flex items-center gap-x-2 text-sm font-medium rounded-lg border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50 disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700 dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600"
                onClick={closeModal}
              >
                Close
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  ) : null;
};

export default DetailsModel;
