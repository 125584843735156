import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import moment from "moment";
import { notify } from "../../helper/toast";
import { toPng } from "html-to-image";
import download from "downloadjs";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const BookMyAppointment: React.FC = () => {
  const AxiosInstance = useAxios();
  const ref = React.useRef<HTMLDivElement>(null);
  const { clinicId } = useParams<{ clinicId: any }>();

  // State variables
  const [clinicName, setClinicName] = useState("");
  const [doctors, setDoctors] = useState<any[]>([]);
  const [selectedDoctor, setSelectedDoctor] = useState("");
  const [patientName, setPatientName] = useState("");
  const [isDoctorSelected, setIsDoctorSelected] = useState(false);
  const [isPatientNameEntered, setIsPatientNameEntered] = useState(false);
  const [successModal, setSuccessModal] = useState(false);
  const [gernatedToken, setGernatedToken] = React.useState<any>("");
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  useEffect(() => {
    fetchClinicDetails(clinicId);
  }, [clinicId]);

  // Fetch clinic details
  const fetchClinicDetails = (clinicId: string) => {
    AxiosInstance.get(`${ApiEndPoints.getBookingDoctos}/${clinicId}`)
      .then((res) => {
        setClinicName(res.data?.clinicName);
        setDoctors(res.data.doctors);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {});
  };

  const bookAppointment = () => {
    AxiosInstance.post(ApiEndPoints.bookAppointment, {
      doctor: selectedDoctor,
      clinic: clinicId,
      name: patientName,
      date: `${selectedDate.getFullYear()}-${
        selectedDate.getMonth() + 1
      }-${selectedDate.getDate()}`,
    })
      .then((res: any) => {
        if (res?.status) {
          setGernatedToken(res?.data);
          setSuccessModal(true);
          notify(res?.msg, "success");
          convertTokenToImage();
        }
      })
      .catch((error) => {
        notify("Something went wrong", "error");
        console.log(error);
      })
      .finally(() => {
        setPatientName("");
        setSelectedDoctor("");
      });
  };

  const convertTokenToImage = React.useCallback(async () => {
    if (ref.current === null) {
      return;
    }

    const imgBase64_dump = await toPng(ref.current, {
      quality: 0.01,
      pixelRatio: 1,
      height: ref.current.scrollHeight,
      canvasWidth: ref.current.scrollWidth,
      canvasHeight: ref.current.scrollHeight,
      style: { backgroundColor: "transparent" },
    });

    const imgBase64_again = await toPng(ref.current, {
      quality: 1,
      pixelRatio: 1,
      height: ref.current.scrollHeight,
      canvasWidth: ref.current.scrollWidth * 2,
      canvasHeight: ref.current.scrollHeight * 2,
      style: { backgroundColor: "transparent" },
    });
    download(imgBase64_again, "appointment.png", "image/png");
  }, [ref]);

  // Handle doctor selection
  const handleDoctorSelect = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedDoctor(event.target.value);
    setIsDoctorSelected(true);
  };

  // Handle patient name input
  const handlePatientNameInput = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setPatientName(event.target.value);
    setIsPatientNameEntered(true);
  };

  // Handle form submission
  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    bookAppointment();
  };

  return (
    <div className="flex items-center justify-center h-full">
      <div className="container mx-auto px-4 py-8 bg-white rounded-lg shadow-lg justify-center items-center mt-10">
        <div className="flex-shrink-0">
          <a title="vaidya kram" className="flex">
            <img
              className="w-auto h-24"
              src="https://ik.imagekit.io/kvsrt5kfk/vaidyakramlogo.jpg?updatedAt=1712895301007"
              alt=""
            />
          </a>
        </div>
        <p className="text-lg mb-4">Clinic: {clinicName}</p>
        <form
          onSubmit={handleSubmit}
          className="space-y-4 justify-center items-center"
        >
          <div className="w-2/2">
            <label htmlFor="doctor" className="block font-medium text-gray-700">
              Select Doctor:
            </label>
            <select
              id="doctor"
              className={`block w-full mt-1 border ${
                isDoctorSelected ? "border-blue-500" : "border-blue-500"
              } rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 p-2`}
              value={selectedDoctor}
              onChange={handleDoctorSelect}
              required
            >
              <option value="">Select Doctor</option>
              {doctors.map((doctor) => (
                <option key={doctor.id} value={doctor.id}>
                  {doctor.name}
                </option>
              ))}
            </select>
          </div>
          <div className="w-2/2">
            <label
              htmlFor="patient"
              className="block font-medium text-gray-700"
            >
              Patient Name:
            </label>
            <input
              id="patient"
              type="text"
              className={`block w-full mt-1 border ${
                isPatientNameEntered ? "border-blue-500" : "border-blue-500"
              } rounded-md shadow-sm focus:border-blue-500 focus:ring-blue-500 m-x-10 p-2`}
              value={patientName}
              onChange={handlePatientNameInput}
              required
            />
          </div>
          <div
              style={{
                border: "1px solid #ccc",
                borderRadius: "5px",
                padding: "10px",
              }}
            >
              <DatePicker
                selected={selectedDate}
                onChange={(date: Date) => setSelectedDate(date)}
                minDate={new Date()}
                maxDate={new Date(new Date().setDate(new Date().getDate() + 2))}
                dateFormat="dd-MM-yyyy"
              />
            </div>
            <p className="mt-2 text-sm text-black-900 font-lora">
              Appointment Date:{" "}
              {`${selectedDate.getDate()}-${
                selectedDate.getMonth() + 1
              }-${selectedDate.getFullYear()}`}
            </p>
          <button
            type="submit"
            className="inline-block px-6 py-2 text-base font-medium leading-6 text-center text-white uppercase transition bg-blue-600 rounded-lg shadow ripple waves-light hover:bg-blue-700 focus:outline-none"
          >
            Submit
          </button>
        </form>
      </div>
      {successModal && (
        <div
          id="my-token-details"
          ref={ref}
          className="fixed inset-0 flex items-center justify-center z-50"
        >
          <div className="fixed inset-0 bg-black opacity-50"></div>

          <div className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform duration-300 scale-100 animate-fade-in-down">
            <div className="px-4 py-2 flex justify-between items-center">
              <h2 className="text-xl font-bold text-gray-800">
                Your Token Details
              </h2>
              <button onClick={() => setSuccessModal(false)}>
                <svg
                  className="h-5 w-5 text-gray-800"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M6 18L18 6M6 6l12 12"
                  />
                </svg>
              </button>
            </div>

            <div className="p-4">
              <div className="bg-yellow-500 text-gray-700 rounded-md px-4 py-2 mb-4">
                <span className="font-bold text-lg">Important!</span> This token
                is valid for today only,{" "}
                {moment(gernatedToken?.date).format("DD-MM-YYYY")}. If you are
                unable to attend your appointment, you will need to generate a
                new token again. Please take screenshot of this token for future
              </div>

              <p className="mb-4  text-gray-700 font-bold text-lg">
                Your Token Number Is : {gernatedToken?.token}
              </p>
              <p className="mb-4  text-gray-700 font-bold text-lg">
                Your Name : {gernatedToken?.name}
              </p>
              <p className="mb-4  text-gray-700 font-bold text-lg">
                Clinic Name : {gernatedToken?.clinic}
              </p>
              <p className="mb-4  text-gray-700 font-bold text-lg">
                Doctor Name : {gernatedToken?.doctor}
              </p>

              <button
                className="mb-4 py-2 px-4 bg-green-500 text-white rounded block"
                onClick={() => {
                  window.open(
                    "https://vaidyakram.in/live-status/" +
                      gernatedToken.clinicId,
                    "_blank"
                  );
                }}
              >
                Live Status
              </button>
              <button
                onClick={() => setSuccessModal(false)}
                className="mb-4 py-2 px-4 bg-green-500 text-white rounded block"
              >
                Thanks! for appointment
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BookMyAppointment;
