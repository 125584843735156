import React, { useEffect, useState } from "react";
import ClinicDashboardNavBar from "../../components/app/DashboardNavBar/ClinicDashboardNavBar";
import DoctorCard from "../../components/widget/DoctorCard";
import DashboardHeader from "../../components/header/DashboardHeader";
import DoctorTokenChart from "../../components/widget/DoctorTokenChart";
import DoctorCurrentTokenChart from "../../components/widget/DoctorCurrentTokenChart";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { notify } from "../../helper/toast";
import LoadingSpinner from "../../helper/LoadingSpinner";
import { useNavigate } from "react-router-dom";
import { checkSubscriptionExpiration } from "../../helper/utils";
import DoctorPatientsList from "./DoctorPatientsList";
const ClinicDashboard: React.FC = () => {
  const AxiosInstance = useAxios();
  const navigate = useNavigate();
  const [loading, setLoading] = React.useState(false);
  const [clinicId, setClinicId] = React.useState(
    localStorage.getItem("clinicId")
  );
  const [doctorList, setDoctorList] = React.useState([]);
  const [clinicBasicInfo, setClinicBasicInfo] = React.useState<any>({});
  const [doctorListModal, setDoctorModalList] = useState<any>(false);
  const [patientList, setPatientList] = useState([]);
  useEffect(() => {
    getDoctorList();
    getClinicBasicInfo();
  }, []);

  const getClinicBasicInfo = () => {
    AxiosInstance.get(`${ApiEndPoints.clinicBasicInfo}/${clinicId}`)
      .then((response: any) => {
        setClinicBasicInfo(response?.data);

        let subscriptionInfo = checkSubscriptionExpiration(
          response?.data?.subscriptionEndDate
        );
        if (subscriptionInfo?.expired) {
          notify(subscriptionInfo?.message, "error");
          localStorage.clear();
          navigate("/login");
        }
      })
      .catch((error) => {
        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getDoctorList = () => {
    setLoading(true);
    AxiosInstance.get(`${ApiEndPoints.getAllDoctorToken}/${clinicId}`)
      .then((response: any) => {
        setDoctorList(response?.data);
      })
      .catch((error) => {
        console.log(error);

        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const getpatientsList = (doctorId: string) => {
    AxiosInstance.get(`${ApiEndPoints.getDoctorPatientsList}/${doctorId}`)
      .then((response: any) => {
        setPatientList(response?.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const openDoctorPatientsList = (e: any, id: any) => {
    getpatientsList(id?.doctorId);
    setDoctorModalList(true);
  };
  return (
    <div className="p-4 sm:ml-64">
      <ClinicDashboardNavBar />
      <div>
        <DashboardHeader />
        {loading ? (
          <LoadingSpinner />
        ) : (
          <div>
            <div>
              {doctorList && doctorList.length > 0 && (
                <div>
                  <div>
                    <DoctorTokenChart
                      doctorData={doctorList}
                    ></DoctorTokenChart>
                  </div>
                </div>
              )}
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 xl:grid-cols-3 gap-4">
              {doctorList &&
                doctorList.length > 0 &&
                doctorList.map((doctor: any, index) => {
                  return (
                    <div onClick={(e) => openDoctorPatientsList(e, doctor)}>
                      <DoctorCard
                        key={index}
                        doctorName={doctor?.doctorName}
                        currentToken={doctor?.currentToken}
                        totalToken={doctor?.totalToken}
                        isAvailable={doctor?.isDoctorAvailable}
                      />
                    </div>
                  );
                })}
            </div>
          </div>
        )}

        {!loading && doctorList.length === 0 && (
          <div className="flex justify-center items-center flex-col">
            <div>
              <p className="text-lg font-lora">Add doctor to start</p>
            </div>
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-4 font-lora"
              onClick={() => navigate("/addDoctor")}
            >
              Add Doctor
            </button>
          </div>
        )}
        {
          <DoctorPatientsList
            patientList={patientList}
            showModal={doctorListModal}
            closeModal={() => setDoctorModalList(false)}
          />
        }
      </div>
    </div>
  );
};

export default ClinicDashboard;
