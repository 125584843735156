import React, { useEffect, useState } from "react";
import DoctorDashboardNavBar from "../../components/app/DashboardNavBar/DoctorDashboardNavBar";
import ModalComponent from "../../components/widget/AddDoctorModal";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { notify } from "../../helper/toast";
import LoadingSpinner from "../../helper/LoadingSpinner";
import ClinicDashboardNavBar from "../../components/app/DashboardNavBar/ClinicDashboardNavBar";
import { placeholder } from "@babel/types";

const Help: React.FC = () => {
  const AxiosInstance = useAxios();
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [clinicNameOnChange, setClinicNameOnChange] = useState("");
  const [issueType, setIssueType] = useState("");
  const [clinicName, setClinicName] = React.useState(
    localStorage.getItem("clinicName")
  );
  const [doctorName, setDcotorName] = React.useState(
    localStorage.getItem("doctorName")
  );
  const IssueTypeList = [
    { label: "Forgot your password", value: "Forgot your password" },
    { label: "Token not working", value: "Token not working" },
    { label: "Other", value: "Other" },
  ];

  const handleSubmit = (event: any) => {
    console.log("submitting calling");
  };

  return (
    <div className="p-4 sm:ml-64">
      {clinicName ? <ClinicDashboardNavBar /> : ""}
      {doctorName ? <DoctorDashboardNavBar /> : ""}

      <form
        action="https://webto.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8"
        method="POST"
        className="space-y-6 px-6 lg:px-8 pb-4 sm:pb-6 xl:pb-8"
        onSubmit={handleSubmit}
      >
        <input type="hidden" name="orgid" value="00D2w00000RqUJN"></input>
        <input
          type="hidden"
          name="retURL"
          id="retURL"
          value="https://vaidyakram.in/clinicDashboard"
        />
        <input
          type="hidden"
          name="Doctor_Name__c"
          value={doctorName ? doctorName : ""}
        ></input>

        <h3 className="text-xl font-medium text-gray-900 dark:text-white">
          Drop Us a Line
        </h3>
        {!clinicName && !doctorName ? (
          <label
            htmlFor="clinicname"
            className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
          >
            Clinic Name
          </label>
        ) : (
          ""
        )}
        <input
          type={clinicName || doctorName ? "hidden" : "Clinic_Name__c"}
          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
          name="Clinic_Name__c"
          value={clinicName ? clinicName : clinicNameOnChange}
          onChange={(e) => setClinicNameOnChange(e.target.value)}
          placeholder="XYZ Hospital"
        ></input>
        <div>
          <label
            htmlFor="name"
            className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
          >
            Name
          </label>
          <input
            onChange={(e) => setName(e.target.value)}
            type="name"
            name="name"
            id="name"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="Adam Bristh"
            required
          />
        </div>
        <div>
          <label
            htmlFor="email"
            className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
          >
            Email
          </label>
          <input
            onChange={(e) => setEmail(e.target.value)}
            type="email"
            name="email"
            id="email"
            placeholder="AdamBristh@gmail.com"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            required
          />
        </div>
        <div className="form-group">
          <label
            htmlFor="issue"
            className="block text-sm font-medium text-gray-700"
          >
            Issue Type
          </label>
          <select
            id="Issue_Type__c"
            name="Issue_Type__c"
            value={issueType}
            onChange={(e) => setIssueType(e.target.value)}
            className="mt-1 block w-full py-2 px-3 border border-gray-300 bg-white rounded-md shadow-sm focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
          >
            <option value="">Select a Reason</option>
            {IssueTypeList.map((issueType) => (
              <option key={issueType.value} value={issueType.value}>
                {issueType.label}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label
            htmlFor="Comment"
            className="text-sm font-medium text-gray-900 block mb-2 dark:text-gray-300"
          >
            Comment
          </label>
          <textarea
            onChange={(e) => setName(e.target.value)}
            name="description"
            id="description"
            className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
            placeholder="Add your comment."
            required
          ></textarea>
        </div>
        <button
          type="submit"
          className="w-full text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
        >
          Submit
        </button>
      </form>
    </div>
  );
};

export default Help;
