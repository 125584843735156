import React, { useState } from "react";
import Select from "react-select";
import TimePicker from "react-time-picker";
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";

interface AddDaysModalInterface {
  showModal: boolean;  
  submitDays: any;
}
const AddDaysModal = ({ showModal, submitDays }: AddDaysModalInterface) => {
  const dayOptions = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  const [selectedDays, setSelectedDays] = useState<any>([]);
  const handleDayChange = (index: number, value: any) => {
    setSelectedDays((prevDays: any) =>
      prevDays.map((day: any, i: number) =>
        i === index ? { ...day, day: value } : day
      )
    );
  };

  const handleStartTimeChange = (index: number, value: any) => {
    setSelectedDays((prevDays: any) =>
      prevDays.map((day: any, i: number) =>
        i === index ? { ...day, startTime: value } : day
      )
    );
  };

  const handleEndTimeChange = (index: number, value: any) => {
    setSelectedDays((prevDays: any) =>
      prevDays.map((day: any, i: number) =>
        i === index ? { ...day, endTime: value } : day
      )
    );
  };

  const addDay = () => {
    if (selectedDays.length > 6) {
      return;
    }
    setSelectedDays([...selectedDays, { day: "", startTime: "", endTime: "" }]);
  };

  const removeDay = (index: number) => {
    setSelectedDays((prevDays: any) =>
      prevDays.filter((day: any, i: number) => i !== index)
    );
  };

  const handleSubmit = () => {
    const requestData = selectedDays.map((day: any) => ({
      dayOfWeek: day.day,
      slots: [
        {
          startTime: day.startTime + " " + "AM",
          endTime: day.endTime + " " + "PM",
        },
      ],
    }));
    submitDays(requestData);
    setSelectedDays([]);
  };

  return (
    <div className={`fixed inset-0 z-50 flex items-center justify-center ${showModal ? "" : "hidden"}`}>
      <div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>
  {showModal && (
    <div
      id="authentication-modal"
      aria-hidden="true"
      className={`${
        showModal ? "" : "hidden"
      } overflow-x-hidden overflow-y-auto fixed inset-0 flex justify-center items-center z-50`}
    >
      <div className="bg-white relative w-full max-w-md px-4 md:p-8 m-10 rounded-lg shadow-md" style={{ maxHeight: "80%", overflowY: "auto" }}>
        
        <h2 className="text-xl font-bold mb-4">Select Days and Times</h2>
        <div className="space-y-4">
          {selectedDays.map((day: any, index: number) => (
            <div key={index}>
              <Select
                className="basic-single"
                classNamePrefix="select"
                options={dayOptions}
                onChange={(selectedOption: any) =>
                  handleDayChange(index, selectedOption?.value)
                }
                value={dayOptions.find(
                  (option) => option.value === day.day
                )}
              />
              <div className="flex space-x-4">
                <TimePicker
                  onChange={(value) => handleStartTimeChange(index, value)}
                  value={day.startTime}
                  className="w-full"
                  clearIcon={null}
                />
                <TimePicker
                  onChange={(value) => handleEndTimeChange(index, value)}
                  value={day.endTime}
                  className="w-full"
                  clearIcon={null}
                />
              </div>
              <button
                onClick={() => removeDay(index)}
                className="text-red-500 hover:text-red-700"
              >
                Remove
              </button>
            </div>
          ))}
          <button
            onClick={addDay}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Add Day
          </button>
          <button
            onClick={handleSubmit}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded ml-2"
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  )}
</div>

  );
};

export default AddDaysModal;
