import React, { useState } from "react";

interface DoctorPatientsListProps {
  showModal: boolean;
  closeModal: () => void;
  patientList: { name: string; tokenNumber: number }[];
}

const DoctorPatientsList: React.FC<DoctorPatientsListProps> = ({
  showModal,
  closeModal,
  patientList,
}) => {
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const filterList = () => {
    return patientList.filter(
      (item) =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.tokenNumber.toString().includes(searchTerm)
    );
  };

  return (
    <div className={`fixed inset-0 z-50 ${showModal ? "" : "hidden"}`}>
      <div className="modal-overlay absolute inset-0 bg-black opacity-50"></div>
      {showModal && (
        <div
          id="authentication-modal"
          className="overflow-x-hidden overflow-y-auto fixed inset-0 flex justify-center items-center z-50"
        >
          <div className="bg-white relative w-full max-w-md px-4 md:p-8 m-10 rounded-lg shadow-md" style={{ maxHeight: "80%", overflowY: "auto" }}>
            <span
              className="close absolute top-2 right-2 text-2xl cursor-pointer"
              onClick={closeModal}
            >
              &times;
            </span>
            <h2 className="text-lg font-bold mb-4">Doctor Patients List</h2>
            <input
              type="text"
              placeholder="Search by name or token number"
              value={searchTerm}
              onChange={handleSearchInputChange}
              className="border border-gray-300 rounded-md p-2 mb-4 w-full"
            />
            <ul className="divide-y divide-gray-200">
              <li className="py-2 flex justify-between font-bold">
                <span className="text-sm" style={{color: "#4a5568"}}>Patient Name</span>
                <span className="text-sm" style={{color: "#4a5568"}}>Token Number</span>
              </li>
              {filterList().map((patient, index) => (
                <li key={index} className="py-2 flex justify-between">
                  <span className="text-sm">{patient.name.toUpperCase()}</span>
                  <span className="text-sm">{patient.tokenNumber}</span>
                </li>
              ))}
              {filterList().length === 0 && (
                <li className="py-2 flex justify-center">No matching patients found</li>
              )}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
};

export default DoctorPatientsList;
