import React from "react";

interface DoctorInfo {
  name: string;
  email: string;
  city: string;
  address: string;
  onClick: () => void;
}

const DoctorCard = ({ name, email, city, address, onClick }: DoctorInfo) => {
  return (
    <a
      className="block max-w-md mx-auto bg-white shadow-md rounded-lg overflow-hidden hover:shadow-lg"
      onClick={onClick}
    >
      <div className="px-4 py-5 sm:px-6">
        <h2 className="text-lg font-semibold leading-6 text-gray-900">
          Contact Information
        </h2>
      </div>
      <div className="border-t border-gray-200">
        <dl>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{name}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Email</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
              {email}
            </dd>
          </div>
          <div className="bg-gray-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">City</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">{city}</dd>
          </div>
          <div className="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Address</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2">
              {address}
            </dd>
          </div>
        </dl>
      </div>
    </a>
  );
};

export default DoctorCard;
