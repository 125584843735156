import moment from "moment";
import React, { useState } from "react";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import ModalComponent from "../../components/widget/HelpModal";
import { notify } from "../../helper/toast";
import { useNavigate } from "react-router-dom";

const LandingPageFooter: React.FC = () => {
  const AxiosInstance = useAxios();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [description, setDescription] = useState("");
  const [showModal, setShowModal] = useState(false);

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setDescription(e.target.value);
  };
  const navigate = useNavigate();

  const handleSubmit = () => {
    // Handle form submission here

    AxiosInstance.post(ApiEndPoints.leadSupport, {
      email,
      name,
      description,
    })
      .then((response: any) => {
        notify("Submitted Successfully", "success");
      })
      .catch((error) => {
        console.log(error);
        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      })
      .finally(() => {
        setEmail("");
        setName("");
        setDescription("");
      });
  };

  return (
    <>
      <footer className="bg-gray-50">
        <div className="mx-auto grid max-w-screen-xl gap-y-8 gap-x-12 px-4 py-10 md:grid-cols-2 xl:grid-cols-4 xl:px-10">
          <div className="max-w-sm">
            <div className="mb-6 flex h-12 items-center space-x-2">
              <span className="font-lora text-2xl font-bold">
                <span className="text-vaidyakram-blue font-lora">
                  Vaidyakram.in
                </span>
              </span>
            </div>
            {/* <div className="font-lora text-gray-500">
            Ready to transform your clinic?{" "}
            <a href="/signup" className="text-blue-500 hover:text-blue-700">
              Sign up for free today!
            </a>
          </div> */}
          </div>
          <div className="">
            <div className="font-lora mt-4 mb-2 font-medium xl:mb-4">
              Address
            </div>
            <div className="font-lora text-gray-500">
              Jaipur , <br />
              Rajasthan
            </div>
          </div>
          <div className="">
            <div className="font-lora mt-4 mb-2 font-medium xl:mb-4">Links</div>
            <nav aria-label="Footer Navigation" className="text-gray-500">
              <ul className="space-y-3">
                <li>
                  <a
                    className="font-lora hover:text-blue-600 hover:underline"
                    onClick={() => setShowModal(true)}
                  >
                    Contact
                  </a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="">
            <div className="font-lora mt-4 mb-2 font-medium xl:mb-4">
              Want to talk to us? Leave your email and message here and we will
              get back to you soon.
            </div>
            <div className="flex flex-col">
              <div className="mb-4">
                <input
                  type="name"
                  className="focus:outline mb-2 block h-14 w-full rounded-xl bg-gray-200 px-4 sm:w-80 focus:outline-none focus:ring-1 focus:ring-blue-600"
                  placeholder="Enter your name"
                  value={name}
                  onChange={handleNameChange}
                />
                <input
                  type="email"
                  className="focus:outline mb-2 block h-14 w-full rounded-xl bg-gray-200 px-4 sm:w-80 focus:outline-none focus:ring-1 focus:ring-blue-600"
                  placeholder="Enter your email"
                  value={email}
                  onChange={handleEmailChange}
                />
                <input
                  type="text"
                  className="focus:outline mb-2 block h-14 w-full rounded-xl bg-gray-200 px-4 sm:w-80 focus:outline-none focus:ring-1 focus:ring-blue-600"
                  placeholder="Enter description"
                  value={description}
                  onChange={handleDescriptionChange}
                />
                <button
                  className="block rounded-xl bg-blue-600 px-6 py-3 font-medium text-white"
                  onClick={handleSubmit}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="bg-gray-100">
          <div className="mx-auto flex max-w-screen-xl flex-col gap-y-4 px-4 py-3 text-center text-gray-500 sm:flex-row sm:justify-between sm:text-left">
            <div className="font-lora">
              © {moment().format("YYYY")} Vaidyakram.in. All rights reserved.
            </div>
            <div className="">
              <a className="font-lora" href="#">
                Privacy Policy
              </a>
              <span>|</span>
              <a className="font-lora" href="#">
                Terms of Service
              </a>
            </div>
          </div>
        </div>

        <ModalComponent
          showModal={showModal}
          setShowModal={(e) => setShowModal(e)}
        />
      </footer>
      <meta
        name="description"
        content="Vaidyakram.in empowers patients to generate tokens online or through QR codes, eliminating the need to wait in line."
      />
      <meta
        name="keywords"
        content="queue management, queue application, queue system, token management system, token based system, hospital management system, hospital token system "
      />
      <meta name="author" content="VaidyaKram" />
    </>
  );
};

export default LandingPageFooter;
