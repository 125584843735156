import React from "react";
import LandingPageHeader from "../../components/header/LandingPage";
import LandingPageSection from "../../components/widget/LandingPageSection";
import LandingPageProduct from "../../components/widget/LandingPageProduct";
import Pricing from "../../components/widget/LandingPagePrice";
import LandingPageFooter from "../../components/widget/LandingPageFooter";
import LandingPageFeatures from "../../components/widget/LandingPageFeatures";
import Testimonial from "../../components/widget/LandingTestimonial";

const Home: React.FC = () => {
  React.useEffect(() => {
    const script = document.createElement("script");
    script.async = true;
    script.src = "https://embed.tawk.to/66126c421ec1082f04dfb08b/1hqrvdphf";
    script.charset = "UTF-8";
    script.setAttribute("crossorigin", "*");
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <div className="bg-white">
      <LandingPageHeader />
      <LandingPageSection />
      <LandingPageProduct />
      <LandingPageFeatures />
      <Testimonial/>
      {/* <Pricing /> */}
      <LandingPageFooter />
    </div>
  );
};

export default Home;
