import React from "react";
import { useState, useEffect } from "react";
import DoctorDashboardNavBar from "../../components/app/DashboardNavBar/DoctorDashboardNavBar";
import { useAxios } from "../../config/axios";
import { ApiEndPoints } from "../../constants/api-end-points";
import { notify } from "../../helper/toast";
import LoadingSpinner from "../../helper/LoadingSpinner";
import DoctorCard from "../../components/widget/DoctorCard";

const DoctorDashboard: React.FC = () => {
  const AxiosInstance = useAxios();
  const [isAvailable, setIsAvailable] = useState(true);
  const [loading, setLoading] = useState(false);
  const [doctorId, setDoctorId] = useState(localStorage.getItem("doctorId"));
  const [clinicId, setClinicId] = useState(localStorage.getItem("clinicId"));
  const [doctorToken, setDoctorToken] = useState<any>("");
  useEffect(() => {
    getDoctorToken();
  }, []);
  const getDoctorToken = () => {
    setLoading(true);
    AxiosInstance.get(`${ApiEndPoints.getDoctorTokenForToday}/${doctorId}`)
      .then((response: any) => {
        setDoctorToken(response?.data);
        setIsAvailable(response?.data?.isAvailableToday);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleCheckboxChange = () => {
    AxiosInstance.post(ApiEndPoints.isDoctorAvailable, {
      id: doctorId,
      isAvailable: !isAvailable,
    })
      .then((response: any) => {
        if (response?.status) {
          setIsAvailable(!isAvailable);
          notify("Status Updated", "success");
        }
      })
      .catch((error) => {
        notify(
          error?.msg
            ? error?.msg
            : "something went wrong, please try again later",
          "error"
        );
      });
  };

  const callNextUser = () => {
    AxiosInstance.post(ApiEndPoints.nextUserToken, {
      doctor: doctorId,
      clinic: clinicId,
    })
      .then((response: any) => {
        notify("Next User Called", "success");
      })
      .catch((error) => {
        notify(
          error?.message
            ? error?.message
            : "something went wrong, please try again later",
          "error"
        );
      });
  };
  return (
    <div className="p-4 sm:ml-64">
      <DoctorDashboardNavBar />
      <label className="flex items-center space-x-2 justify-center mb-5">
        <input
          type="checkbox"
          checked={isAvailable}
          onChange={handleCheckboxChange}
          className="form-checkbox h-5 w-5 text-blue-500"
        />
        <span className="text-gray-700">Is Available Today</span>
      </label>
      <div className="justify-center items-center">
        {loading ? (
          <LoadingSpinner />
        ) : doctorToken ? (
          <DoctorCard
            doctorName={doctorToken?.name}
            currentToken={
              doctorToken?.currentToken ? doctorToken?.currentToken : 0
            }
            totalToken={doctorToken?.totalToken ? doctorToken?.totalToken : 0}
            isAvailable={doctorToken?.isAvailableToday}
          />
        ) : (
          <div>no token avaible</div>
        )}
      </div>

      <div className="flex justify-center mt-10">
        <button
          onClick={() => callNextUser()}
          className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800"
        >
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Next Patients
          </span>
        </button>
        <button className="relative inline-flex items-center justify-center p-0.5 mb-2 me-2 overflow-hidden text-sm font-medium text-gray-900 rounded-lg group bg-gradient-to-br from-purple-600 to-blue-500 group-hover:from-purple-600 group-hover:to-blue-500 hover:text-white dark:text-white focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800">
          <span className="relative px-5 py-2.5 transition-all ease-in duration-75 bg-white dark:bg-gray-900 rounded-md group-hover:bg-opacity-0">
            Call Current Token Number
          </span>
        </button>
      </div>
    </div>
  );
};

export default DoctorDashboard;
