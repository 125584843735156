import moment from "moment";
import { notify } from "./toast";

function checkSubscriptionExpiration(endDate: Date) {
  const currentDate = moment();
  const expirationDate = moment(endDate);

  if (expirationDate.isBefore(currentDate)) {
    return { expired: true, message: `Your subscription has expired.` };
  }

  const remainingDays = expirationDate.diff(currentDate, "days");
  if (remainingDays == 7) {
    notify(`Your subscription will expire in ${remainingDays} days.`, "info");
  } else if (remainingDays == 5) {
    notify(`Your subscription will expire in ${remainingDays} days.`, "info");
  } else if (remainingDays > 1 && remainingDays < 3) {
    notify(`Your subscription will expire in ${remainingDays} days.`, "warn");
  } else if (remainingDays == 1) {
    notify(`Your subscription will expire in ${remainingDays} days.`, "warn");
  } else if (remainingDays == 0) {
    notify(`Your subscription will expire today`, "warn");
  } else {
    return { expired: false, message: `` };
  }
}

export { checkSubscriptionExpiration };
