import { io, Socket } from "socket.io-client";
import eventEmitter from "../helper/eventEmitter";

let socket: Socket | null | any = null;

export const socketInit = (clinicId: any) => {
  if (!socket) {
    socket = io("https://api.vaidyakram.in", {
      forceNew: true,
      reconnection: true,
      reconnectionDelay: 1000,
      reconnectionDelayMax: 3000,
      transports: ["websocket"],
      autoConnect: true,
    });

    socket.on("connect", () => {
      console.log("Socket connected");
    });

    socket.on(`currentTokenNumber-${clinicId}`, (data: any) => {
      eventEmitter.emit("currentTokenNumber", data);
    });
    socket.on(`toggleMediaStatus-${clinicId}`, (data: any) => {
      eventEmitter.emit("toggleMediaStatus", data);
    });
    socket.on(`clinicMediaStatus-${clinicId}`, (data: any) => {
      eventEmitter.emit("clinicMediaStatus", data);
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected!");
      let reconnectAttempts = 0;

      const reconnect = () => {
        reconnectAttempts++;
        if (reconnectAttempts > 5) {
          console.error("Failed to reconnect after 5 attempts");
          return;
        }

        console.log(`Reconnecting attempt ${reconnectAttempts}`);
        setTimeout(() => {
          socket.connect();
        }, Math.pow(2, reconnectAttempts) * 1500); // Exponential backoff
      };

      // **Improved reconnect logic with timeout handling:**
      socket.on("connect", () => {
        console.log("Socket connected!");
        clearTimeout(reconnectTimeout); // Clear any pending timeout
      });

      // Set initial timeout for the first attempt
      const reconnectTimeout = setTimeout(reconnect, 0); // Call reconnect immediately
    });

    socket.on("reconnect", () => {
      console.log("Socket reconnected");
      // You can add logic here to notify user about reconnection (optional)
    });

    socket.on("connect_error", (error: any) => {
      console.log("Socket connect_error", error);
      socketReconnect();
    });

    socket.on("error", (error: any) => {
      console.log("Socket error", error);
      socketReconnect();
    });
  } else {
    socket.connect();
  }
};

export const disconnectSocket = () => {
  if (socket) {
    socket.disconnect();
  }
};

export const socketReconnect = () => {
  socket.connect();
};

export const emitSocketEvent = (eventName: string, data?: any) => {
  if (socket) {
    socket.emit(eventName, data);
  }
};
